import { MetaResource } from '@app/@shared/interfaces/paged-resource';
import { DispositionItemPartTypeValue } from '@data/disposition-add-edit/disposition-add-edit.interfaces';
import { DispositionResource } from '@data/dispositions/dispositions.model';
import { SuborderResource } from '@data/orders/orders.model';
import { ServiceResource } from '@data/services/services.interface';
import { StatusResource } from '@data/status/status.interfaces';
import { VehicleResource } from '@data/vehicles/vehicles.model';
import { EnumResource } from '@shared/interfaces/enum-resource';
import { PaginationParams, SortingParams, TabParams } from '@data/filtering/filtering.interface';
import { FormControl, FormGroup } from '@angular/forms';
import { InputOptionResource } from '@app/@feed-inputs';
import { FleetResource } from '@data/fleets/fleets.model';
import { ListTabWithColumns } from '@shared/interfaces/list-tab';
import { UserResource } from '@data/users/users.model';
import { DamageTypeResource } from '@data/damage-types/damage-types.model';

export interface AuthorizationStateModel {
  activeTab: ListTabWithColumns;
  visibleColumns: string[];
  tab_slug: string;
  meta: MetaResource;
}

export type AuthorizationStatusSlug = 'pending' | 'in_progress' | 'updated' | 'accepted' | 'declined' | 'canceled';

export interface AuthorizationVehicle {
  vehicle: VehicleResource;
  fleet: FleetResource;
}

export interface AuthorizationCarService {
  serviceName: string;
  serviceLocation: string;
}

export interface AuthorizationHandling {
  date: string;
  company: string;
  author: string;
}

export interface AuthorizationLastActivity {
  author: string;
  id?: string;
  text: string;
  date: string;
}

export interface AuthorizationAdditional {
  remarks: string;
  attachment: string;
}

export interface AuthorizationActions {
  priority: string;
  author: string;
}

export type AuthorizationResource = DispositionResource;
// export interface AuthorizationResource {
//   id: number;
//   auth_code_id: number | null;
//   number: number | null;
//   status: boolean | null;
//   authorized_at: string | null;
//   created_at: string | null;
//   vehicle: AuthorizationVehicle;
//   fleet: FleetResource;
//   task: any;
//   carService: AuthorizationCarService;
//   completionDate: string;
//   authorizationHandling: AuthorizationHandling;
//   serviceType: string;
//   value: number;
//   lastActivity: AuthorizationLastActivity;
//   additional?: AuthorizationAdditional;
//   actions?: AuthorizationActions;
//   workshop: WorkshopResource;
//   total_prices: any;
// }

export interface AuthorizationFilterParams extends PaginationParams, SortingParams, TabParams {
  vehicles: InputOptionResource;
  number: string;
  workshops: string;
  fleets: string;
  date_from: string;
  date_to: string;
  due_date: string;
  attendant: string;
  priority: string;
  status: string;
  service_type: string;
  self_fleets: string;
  auth_tabs: string;
}

export type AuthorizationFilterParamsFormGroup = FormGroup<{
  vehicles: FormControl<InputOptionResource>;
  number: FormControl<string>;
  workshops: FormControl<string[]>;
  fleets: FormControl<string[]>;
  date_from: FormControl<string>;
  date_to: FormControl<string>;
  due_date: FormControl<string>;
  attendant: FormControl<string>;
  priority: FormControl<string>;
  status: FormControl<string>;
  service_type: FormControl<string>;
  self_fleets: FormControl<string>;
}>;

export type DispositionAuthStatusName = 'accepted' | 'viewed' | 'declined' | 'modified';

export class DispositionAuthStatus {
  // BE statuses
  public static ACCEPTED: DispositionAuthStatusName = 'accepted';
  public static VIEWED: DispositionAuthStatusName = 'viewed';
  public static DECLINED: DispositionAuthStatusName = 'declined';
  public static MODIFIED: DispositionAuthStatusName = 'modified';

  // FE statuses
  public static REQUIRED_STATUS: StatusResource = {
    value: 'required',
    label: 'Wymagana',
    hex_color: 'var(--signal-error-red)',
  };
  public static MODIFIED_STATUS: StatusResource = {
    value: 'modified',
    label: 'Zmodyfikowana',
    hex_color: 'var(--primary-y-yellow)',
  };
  public static VIEWED_STATUS: StatusResource = {
    value: 'viewed',
    label: 'Przesłano do AFL',
    hex_color: 'var(--signal-success-green)',
  };
  public static ACCEPTED_STATUS: StatusResource = {
    value: 'accepted',
    label: 'Przyznana',
    hex_color: 'var(--signal-success-green)',
  };
  public static DECLINED_STATUS: StatusResource = {
    value: 'declined',
    label: 'Odrzucona',
    hex_color: 'var(--ui-g4-gray4)',
  };
  public static WAITING_STATUS: StatusResource = {
    value: 'waiting',
    label: 'Oczekująca',
    hex_color: 'var(--primary-y-yellow)',
  };

  static toStatusResource(status: string | null): StatusResource {
    switch (status) {
      case this.ACCEPTED:
        return this.ACCEPTED_STATUS;
      case this.VIEWED:
        return this.VIEWED_STATUS;
      case this.DECLINED:
        return this.DECLINED_STATUS;
      case this.MODIFIED:
        return this.MODIFIED_STATUS;
      case null:
        return null;
    }
  }
}

export interface DispositionItemAuthResource {
  id: number;
  is_auth_required: boolean;
  auth_code_id: number;
  code: string;
  status: DispositionAuthStatusName;
  authorized_at: string;
  disposition_item: DispositionItemResource;
  user: UserResource;
}

export interface DispositionItemResource {
  id: number;
  disposition_id: number;
  suborder: SuborderResource;
  service: ServiceResource;
  additional_service_id: number;
  quantity: string;
  price_fleet: number;
  price_workshop: number;
  price_fleet_calculated: number;
  price_workshop_calculated: number;
  price_wholesaler_calculated: number;
  vat_rate: number;
  description: string;
  auth: DispositionItemAuthResource;
  service_name: string;
  planned_at: string;
  is_deferred: boolean;
  catalog_number: string;
  part_type: EnumResource<DispositionItemPartTypeValue>;
  producer: string;
  deactivates_tyre_quantity_validation: boolean;
  invoice_id: number;
  damage_type: DamageTypeResource;
}

export type DispositionAuthGroupName = 'pso' | 'fla';

export class DispositionAuthGroup {
  static PSO: DispositionAuthGroupName = 'pso';
  static FLA: DispositionAuthGroupName = 'fla';
}

export type DispositionAuthModeName = 'pso' | 'fla' | 'common';

export class DispositionAuthMode {
  static PSO: DispositionAuthModeName = 'pso';
  static FLA: DispositionAuthModeName = 'fla';
  static COMMON: DispositionAuthModeName = 'common';
}
